import { Box, Grid, Heading, Text, Container, Button, Icon, useMediaQuery } from '@chakra-ui/react';
import React, { useState } from 'react';
import Layout from '../../components/Layout';
import SEO from '../../components/seo';
import { Hero, SubHero, ProjectCardModal } from '../../components/shared';
import { AiOutlineArrowRight } from 'react-icons/ai';
import { useProjectsCommercialPage } from '../../GraphQL/Queries';

const Projects = ({ location }) => {
  const data = useProjectsCommercialPage();

  const { acf_hero, acf_projects_content } = data.pageBy;

  const { projectList } = data.pageBy.acf_projects_slider;

  const [isSmallScreen] = useMediaQuery('(max-width: 500px)');
  const [limit, setLimit] = useState(3);

  return (
    <Layout location={location}>
      <SEO title="Projects" />
      <Hero
        noCta={true}
        noIcon={true}
        heroHeadline={acf_hero.heroTitle}
        heroCopy={acf_hero.heroDescription}
        image={acf_hero.heroImage.sourceUrl}
      />
      <SubHero
        stacked={true}
        heading="Introduction"
        body="At CH Global Construction, we emphasize quality and timely delivery with all projects we work on. Whether it’s a home or commercial project, we make sure to take note of our client’s needs and expectations so that their final picture is fully represented in our work. Our clients can expect a team-oriented approach to their individual projects as well as customer satisfaction."
      />
      <Box
        mt={{ md: '3rem' }}
        pos="relative"
        className="chipped__conainter"
        pb="2rem"
        mb={{ base: '1rem', md: '4rem' }}
        bg={{ base: 'primary', md: 'unset' }}
      >
        <Box
          bg="primary"
          zIndex="-1"
          pos="absolute"
          display={{ base: 'none', md: 'block' }}
          h="772px"
          className="chipped__conainter"
        />
        <Container pl={{ base: '2rem', sm: '2rem', md: 'unset' }} pr={{ base: '2rem', md: 'unset' }}>
          <Grid
            pt={{ md: '4rem' }}
            pr={{ base: 'unset', md: '4rem' }}
            templateColumns={{
              base: 'repeat(1, minmax(100px, 303px))',
              md: 'repeat(2, minmax(auto, 303px))',
              lg: 'repeat(3, minmax(auto, 303px))',
            }}
            pb="2rem"
            gap={20}
            rowGap="16"
          >
            <Box maxW={{ base: '275px', md: '306px' }}>
              <Heading
                pt="1rem"
                mt="2rem"
                color="secondary"
                borderTopWidth={{ md: '5px' }}
                borderTopColor={{ md: 'white' }}
                borderTopStyle={{ md: 'solid' }}
                fontSize="35px"
              >
                {acf_projects_content.projectsFirstTitle}
              </Heading>
              <Heading
                pr="2rem"
                pb="1rem"
                color="white"
                borderBottomWidth="5px"
                borderBottomColor="white"
                borderBottomStyle="solid"
              >
                {acf_projects_content.projectsSecondTitle}
              </Heading>
              <Text color="white" pt="1.5rem" maxW="80%" fontSize="19px">
                {acf_projects_content.projectsContentDescription}
              </Text>
            </Box>

            {isSmallScreen
              ? projectList
                  .slice(0, limit)
                  .map((project) => (
                    <ProjectCardModal
                      image={project.projectGallery}
                      title={project.projectName}
                      wide
                      location={project.projectLocation}
                    />
                  ))
              : projectList.map((project) => (
                  <ProjectCardModal
                    image={project.projectGallery}
                    title={project.projectName}
                    location={project.projectLocation}
                  />
                ))}
          </Grid>

          <Button
            display={{ base: limit === projectList.length ? 'none' : 'block', md: 'none' }}
            onClick={() => setLimit(projectList.length)}
          >
            <Icon
              as={AiOutlineArrowRight}
              bg="primary"
              w="20px"
              h="20px"
              p="1"
              mr="2"
              borderRadius="50%"
              color="white"
            />
            Load more projects
          </Button>
        </Container>
      </Box>
    </Layout>
  );
};

export default Projects;
